import { AnimationCallbacks, AnimationData, AnimatorManager, IAnimations, Sequence } from 'feature-animations'
import { getDefaultCompId, getHoverCompId } from '@wix/thunderbolt-commons'
import type { AnimationParamsOverrides, HoverBoxPageConfig } from './types'
import { AnimationsBehavior } from '@wix/thunderbolt-becky-types'
import fastdom from 'fastdom'

const EASE_IN_OUT = 'cubic-bezier(0.420, 0.000, 0.580, 1.000)'
const baseClearAnimation: AnimationData = {
	name: 'BaseClear',
	targetId: '',
	params: {
		props: 'clip,clipPath,webkitClipPath,opacity,transform,transform-origin,visibility,transition',
		immediateRender: false,
		callbacks: {} as AnimationCallbacks,
	},
	delay: 0,
	duration: 0,
}

interface IAnimationHandler {
	animate(
		defaultHoverBoxId: string,
		prevHoverBoxId: string,
		nextHoverBoxId: string,
		removedIds: Array<string>,
		addedIds: Array<string>,
		unchangedIds: Array<string>,
		reverseCb: Function
	): void

	reverseTransitions(defaultCompId: string): Promise<boolean>
}

type AnimationHandlerFactory = (
	animations: IAnimations,
	hoverBoxChildrenData: HoverBoxPageConfig['hoverBoxChildrenData'],
	fixedPositionHoverBoxes: HoverBoxPageConfig['fixedPositionHoverBoxes']
) => IAnimationHandler

export const AnimationHandler: AnimationHandlerFactory = (
	animations,
	hoverBoxChildrenData,
	fixedPositionHoverBoxes
) => {
	const sequencesByHoverBoxId: { [defaultHoverBoxId: string]: { [targetId: string]: Sequence } } = {}

	const clearSequenceByTargetId = (defaultHoverBoxId: string, targetId: string) => () => {
		delete sequencesByHoverBoxId[defaultHoverBoxId][targetId]
	}

	const getElement = (compId: string) => window!.document.getElementById(compId)

	const runSequence = (animatorManager: AnimatorManager, data: AnimationData, callbacks: AnimationCallbacks) =>
		animatorManager.runSequence(
			[
				{ type: 'Animation', data },
				{
					type: 'Animation',
					data: { ...baseClearAnimation, targetId: data.targetId },
				},
			],
			{ callbacks }
		)

	const reverseCallback = (defaultHoverBoxId: string, reverseCb: Function) => {
		if (Object.keys(sequencesByHoverBoxId[defaultHoverBoxId] || {}).length === 0) {
			reverseCb()
		}
	}

	const runAnimations = (
		animatorManager: AnimatorManager,
		animation: AnimationsBehavior,
		targetId: string,
		defaultHoverBoxId: string,
		reverseCb: Function
	) => {
		const { duration, ...restAnimation } = animation

		const onReverseComplete = (seq: Sequence) => {
			seq.pause()

			const baseClearParams: AnimationData['params'] = { ...baseClearAnimation.params }
			baseClearParams.callbacks = {
				onComplete: () => {
					clearSequenceByTargetId(defaultHoverBoxId, targetId)()
					reverseCallback(defaultHoverBoxId, reverseCb)
				},
			}
			const baseClear = { ...baseClearAnimation, params: baseClearParams, targetId }
			animatorManager.runAnimation(baseClear)
		}

		const animationData = {
			...restAnimation,
			targetId,
			duration: Number(duration),
		}
		sequencesByHoverBoxId[defaultHoverBoxId][targetId] = runSequence(animatorManager, animationData, {
			onComplete: clearSequenceByTargetId(defaultHoverBoxId, targetId),
			onInterrupt: clearSequenceByTargetId(defaultHoverBoxId, targetId),
			onReverseComplete,
		})
	}

	const runTransition = (
		animatorManager: AnimatorManager,
		transition: AnimationsBehavior,
		targetId: string,
		overrideParams: AnimationParamsOverrides,
		defaultHoverBoxId: string,
		reverseCb: Function
	) => {
		const { params = {}, duration, ...restTransition } = transition
		const animationParams = { ...params, ...overrideParams }

		const onReverseComplete = (seq: Sequence) => {
			seq.pause()

			if (animationParams.from.rotation !== undefined) {
				fastdom.measure(() => {
					const element = getElement(targetId)!
					element.dataset.angle = String(animationParams.from.rotation)
				})
			}

			const baseClearParams: AnimationData['params'] = { ...baseClearAnimation.params }
			baseClearParams.callbacks = {
				onComplete: () => {
					clearSequenceByTargetId(defaultHoverBoxId, targetId)()
					reverseCallback(defaultHoverBoxId, reverseCb)
				},
			}
			const baseClear = { ...baseClearAnimation, params: baseClearParams, targetId }
			animatorManager.runAnimation(baseClear)
		}

		fastdom.measure(() => {
			const element = getElement(targetId)!
			element.style.transition = `
        		background-color ${duration}s ${EASE_IN_OUT} ${restTransition.delay}s,
        		color ${duration}s ${EASE_IN_OUT} ${restTransition.delay}s
    		`
		})

		const animationData = {
			...restTransition,
			targetId,
			duration: Number(duration),
			params: animationParams,
		}
		sequencesByHoverBoxId[defaultHoverBoxId][targetId] = runSequence(animatorManager, animationData, {
			onComplete: clearSequenceByTargetId(defaultHoverBoxId, targetId),
			onInterrupt: clearSequenceByTargetId(defaultHoverBoxId, targetId),
			onReverseComplete,
		})
	}

	const handleModesInOutBehaviors = (
		animatorManager: AnimatorManager,
		defaultHoverBoxId: string,
		prevHoverBoxId: string,
		nextHoverBoxId: string,
		removedIds: Array<string>,
		addedIds: Array<string>,
		reverseCb: Function
	) => {
		removedIds.forEach((id) => {
			const compBehavior = hoverBoxChildrenData[prevHoverBoxId][id].behavior
			const animation = compBehavior && compBehavior.out

			if (animation) {
				runAnimations(animatorManager, animation, getDefaultCompId(id), defaultHoverBoxId, reverseCb)
			}
		})

		addedIds.forEach((id) => {
			const compBehavior = hoverBoxChildrenData[nextHoverBoxId][id].behavior
			const animation = compBehavior && compBehavior.in

			if (animation) {
				runAnimations(animatorManager, animation, getDefaultCompId(id), defaultHoverBoxId, reverseCb)
			}
		})
	}

	const handleModesTransitionBehaviors = (
		animatorManager: AnimatorManager,
		defaultHoverBoxId: string,
		prevHoverBoxId: string,
		nextHoverBoxId: string,
		unchangedIds: Array<string>,
		reverseCb: Function
	) => {
		const prevHoverBoxData = hoverBoxChildrenData[prevHoverBoxId]

		unchangedIds.forEach((id) => {
			const compData = prevHoverBoxData[id] || prevHoverBoxData[getHoverCompId(id)]
			const compBehavior = compData.behavior
			const animation = compBehavior && compBehavior.change

			if (animation) {
				const parentMeasures = getElement(getDefaultCompId(nextHoverBoxId))!.getBoundingClientRect()
				const { top: absoluteTop, left: absoluteLeft } = parentMeasures
				const { y: top = 0, x: left = 0, width, height, rotationInDegrees: rotation } = compData.layout

				const params = {
					from: {
						top: top + absoluteTop - (fixedPositionHoverBoxes.includes(nextHoverBoxId) ? scrollY : 0),
						left: left + absoluteLeft,
						width,
						height,
						rotation,
					},
				}

				runTransition(animatorManager, animation, getDefaultCompId(id), params, defaultHoverBoxId, reverseCb)
			}
		})
	}

	return {
		async animate(
			defaultHoverBoxId,
			prevHoverBoxId,
			nextHoverBoxId,
			removedIds,
			addedIds,
			unchangedIds,
			reverseCb
		) {
			const animatorManager = await animations.getInstance()
			sequencesByHoverBoxId[defaultHoverBoxId] = {}

			// in / out animations of components that are not in both modes
			handleModesInOutBehaviors(
				animatorManager,
				defaultHoverBoxId,
				prevHoverBoxId,
				nextHoverBoxId,
				removedIds,
				addedIds,
				reverseCb
			)
			// transition of components from default to hover mode (and vice versa)
			handleModesTransitionBehaviors(
				animatorManager,
				defaultHoverBoxId,
				prevHoverBoxId,
				nextHoverBoxId,
				unchangedIds,
				reverseCb
			)
		},

		async reverseTransitions(defaultCompId) {
			const animatorManager = await animations.getInstance()

			const reverseSequences = Object.values(sequencesByHoverBoxId[defaultCompId] || {})
			reverseSequences.forEach((seq) => {
				animatorManager.reverse(seq.get())
			})

			return reverseSequences.length > 0
		},
	}
}
