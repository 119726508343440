import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	IPageWillMountHandler,
	CompEventsRegistrarSym,
	ICompEventsRegistrar,
	Props,
	IPropsStore,
	BrowserWindowSymbol,
	BrowserWindow,
	CompsLifeCycleSym,
	ICompsLifeCycle,
	PropsMap,
	PlatformPropsSyncManagerSymbol,
	IPlatformPropsSyncManager,
	StructureAPI,
	IStructureAPI,
} from '@wix/thunderbolt-symbols'
import { getDefaultCompId, getHoverCompId } from '@wix/thunderbolt-commons'
import type { HoverBoxPageConfig, IHoverBoxAPI, Modes } from './types'
import { HoverBoxAPISym, name } from './symbols'
import { Animations, IAnimations } from 'feature-animations'
import { AnimationHandler } from './AnimationHandler'

const hoverBoxFactory = (
	{
		defaultModeComps,
		hoverModeComps,
		hoverBoxChildrenData,
		fixedPositionHoverBoxes,
		hoverBoxParent,
	}: HoverBoxPageConfig,
	hoverBoxApi: IHoverBoxAPI,
	compEventsRegistrar: ICompEventsRegistrar,
	propsStore: IPropsStore,
	structureAPI: IStructureAPI,
	window: BrowserWindow,
	compsLifeCycle: ICompsLifeCycle,
	platformPropsSyncManager: IPlatformPropsSyncManager,
	animations: IAnimations
): IPageWillMountHandler => {
	const animationHandler = animations && AnimationHandler(animations, hoverBoxChildrenData, fixedPositionHoverBoxes)

	const defaultModeHoverBoxIds = Object.keys(defaultModeComps)
	const hoverModeHoverBoxIds = Object.keys(hoverModeComps)
	const hoverBoxInReverseProcess: { [defaultHoverBoxId: string]: boolean } = {}

	const updateHoverBoxesMode = (defaultCompId: string, hoverCompId: string, mode: Modes) => {
		const overrideProps = { mode }
		hoverBoxApi.updateHoverBoxesMode(defaultCompId, hoverCompId, hoverBoxParent, mode)

		platformPropsSyncManager.triggerPlatformPropsSync(defaultCompId, overrideProps)
		platformPropsSyncManager.triggerPlatformPropsSync(hoverCompId, overrideProps)
	}

	const getChangedComponentsIdsInMode = (mode: Modes, defaultComps: Array<string>, hoverComps: Array<string>) => {
		const isDefaultMode = mode === 'default'
		return {
			removedIds: isDefaultMode
				? hoverComps.filter((id) => !defaultComps.includes(getDefaultCompId(id))) // comps in hover but not in default mode
				: defaultComps.filter((id) => !hoverComps.includes(id) && !hoverComps.includes(getHoverCompId(id))), // comps in default but not in hover mode
			addedIds: isDefaultMode
				? defaultComps.filter((id) => !hoverComps.includes(id) && !hoverComps.includes(getHoverCompId(id))) // comps in default but not in hover mode
				: hoverComps.filter((id) => !defaultComps.includes(getDefaultCompId(id))), // comps in hover but not in default mode
			unchangedIds: isDefaultMode
				? hoverComps.filter((id) => defaultComps.includes(getDefaultCompId(id))) // comps in both modes
				: defaultComps.filter((id) => hoverComps.includes(id) || hoverComps.includes(getHoverCompId(id))), // comps in both modes
		}
	}

	const handleModesBehaviors = (mode: Modes, reverseMode: Modes, prevHoverBoxId: string, nextHoverBoxId: string) => {
		const isDefaultMode = mode === 'default'
		const defaultCompId = isDefaultMode ? nextHoverBoxId : prevHoverBoxId
		const hoverCompId = isDefaultMode ? prevHoverBoxId : nextHoverBoxId

		const defaultComps = defaultModeComps[defaultCompId]
		const hoverComps = hoverModeComps[hoverCompId]

		const { removedIds, addedIds, unchangedIds } = getChangedComponentsIdsInMode(mode, defaultComps, hoverComps)
		const reverseCb = () => {
			updateHoverBoxesMode(defaultCompId, hoverCompId, reverseMode)
			hoverBoxInReverseProcess[defaultCompId] = false
		}

		animationHandler.animate(
			defaultCompId,
			prevHoverBoxId,
			nextHoverBoxId,
			removedIds,
			addedIds,
			unchangedIds,
			reverseCb
		)
	}

	const handleHoverBoxesTransitions = async () => {
		if (animationHandler) {
			const props: PropsMap = {}
			// perform transitions after hoverBox comp layout stage
			defaultModeHoverBoxIds.forEach(
				(defaultCompId) =>
					(props[defaultCompId] = {
						modeDidUpdate: (mode: string) => {
							if (!hoverBoxInReverseProcess[defaultCompId] && mode === 'default') {
								const hoverCompId = getHoverCompId(defaultCompId)
								handleModesBehaviors(mode, 'hover', hoverCompId, defaultCompId)
							}
						},
					})
			)
			hoverModeHoverBoxIds.forEach(
				(hoverCompId) =>
					(props[hoverCompId] = {
						modeDidUpdate: (mode: string) => {
							const defaultCompId = getDefaultCompId(hoverCompId)
							if (!hoverBoxInReverseProcess[defaultCompId] && mode === 'hover') {
								handleModesBehaviors(mode, 'default', defaultCompId, hoverCompId)
							}
						},
					})
			)
			propsStore.update(props)
		}
	}

	const onMouseEnterOrLeave = async (defaultCompId: string, hoverCompId: string, mode: Modes) => {
		const hasReverseTransitions = await animationHandler?.reverseTransitions(defaultCompId)
		hoverBoxInReverseProcess[defaultCompId] = hasReverseTransitions

		if (!hasReverseTransitions) {
			updateHoverBoxesMode(defaultCompId, hoverCompId, mode)
		}
	}

	return {
		async pageWillMount() {
			await handleHoverBoxesTransitions()

			defaultModeHoverBoxIds.forEach((defaultCompId) => {
				const hoverCompId = getHoverCompId(defaultCompId)
				compEventsRegistrar.register(defaultCompId, 'onMouseEnter', async () => {
					await onMouseEnterOrLeave(defaultCompId, hoverCompId, 'hover')
				})
				compEventsRegistrar.register(defaultCompId, 'onMouseLeave', async () => {
					await onMouseEnterOrLeave(defaultCompId, hoverCompId, 'default')
				})
			})

			hoverModeHoverBoxIds.forEach((hoverCompId) => {
				const defaultCompId = getDefaultCompId(hoverCompId)
				compEventsRegistrar.register(hoverCompId, 'onMouseLeave', async () => {
					await onMouseEnterOrLeave(defaultCompId, hoverCompId, 'default')
				})
				compEventsRegistrar.register(hoverCompId, 'onMouseEnter', async () => {
					await onMouseEnterOrLeave(defaultCompId, hoverCompId, 'hover')
				})
			})

			// Add events to hover comps as well
			compEventsRegistrar.subscribeToChanges((compId, newActions) => {
				const hoverCompId = getHoverCompId(compId)
				const hoverCompExists = structureAPI.get(hoverCompId)

				if (hoverCompExists) {
					const eventName = Object.keys(newActions)[0]
					compEventsRegistrar.register(hoverCompId, eventName, newActions[eventName])
				}
			})
		},
	}
}

export const HoverBox = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		HoverBoxAPISym,
		CompEventsRegistrarSym,
		Props,
		StructureAPI,
		BrowserWindowSymbol,
		CompsLifeCycleSym,
		PlatformPropsSyncManagerSymbol,
		optional(Animations),
	],
	hoverBoxFactory
)
