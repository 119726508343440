import { IPropsStore, IStructureAPI, Props, StructureAPI } from '@wix/thunderbolt-symbols'
import type { IHoverBoxAPI, Modes } from './types'
import { withDependencies } from '@wix/thunderbolt-ioc'

const hoverBoxAPI = (propsStore: IPropsStore, structureAPI: IStructureAPI): IHoverBoxAPI => {
	const updateHoverBoxesMode = (
		defaultCompId: string,
		hoverCompId: string,
		hoverBoxParents: Record<string, string>,
		mode: Modes
	) => {
		const overrideProps = { mode }

		const oldCompId = mode === 'hover' ? defaultCompId : hoverCompId
		const newCompId = mode === 'hover' ? hoverCompId : defaultCompId

		structureAPI.replaceComponentInParent(hoverBoxParents[defaultCompId], oldCompId, newCompId)

		propsStore.update({
			[defaultCompId]: overrideProps,
			[hoverCompId]: overrideProps,
		})
	}
	return {
		updateHoverBoxesMode,
	}
}

export const HoverBoxAPI = withDependencies([Props, StructureAPI], hoverBoxAPI)
