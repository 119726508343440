import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { HoverBox } from './hoverBox'
import { HoverBoxAPI } from './hoverBoxAPI'
import type { IHoverBoxAPI, Modes } from './types'
import { HoverBoxAPISym } from './symbols'
import { LifeCycle } from '@wix/thunderbolt-symbols'

export const page: ContainerModuleLoader = (bind) => {
	bind(HoverBoxAPISym).to(HoverBoxAPI)
	bind(LifeCycle.PageWillMountHandler).to(HoverBox)
}

export const editor: ContainerModuleLoader = (bind) => {
	bind(HoverBoxAPISym).to(HoverBoxAPI)
}

// Public Types
export { IHoverBoxAPI, Modes }

// Public Symbols
export { HoverBoxAPISym }
